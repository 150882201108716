import React, { Component } from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { withAlert } from "react-alert";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      first_name: "",
      last_name: "",
      email: "",
      msg: "",
      data: [],
    };
    this.send_sms = this.send_sms.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get(this.state.base_url + `api/Api/general`).then((res) => {
      this.setState({ data: res.data });
    });
  }
  send_sms() {
    const alert = this.props.alert;
    const options = {
      headers: { "X-API-KEY": "19913218251000224" },
    };
    const first_name = this.state.first_name;
    const second_name = this.state.last_name;
    const email = this.state.email;
    const msg = this.state.msg;
    if (first_name !== "" || second_name !== "" || email !== "" || msg !== "") {
      axios
        .post(
          this.state.base_url + `api/Api/message`,
          {
            first_name: this.state.first_name,
            second_name: this.state.last_name,
            email: this.state.email,
            msg: this.state.msg,
          },
          options
        )
        .then((response) => {
          this.setState({ first_name: "" });
          this.setState({ last_name: "" });
          this.setState({ email: "" });
          this.setState({ msg: "" });
          alert.success("Your request has been saved!");
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert.error("Fill all the fields please");
    }
  }
  render() {
    return (
      <>
        <Head />
        <section className="common_banner_section clear">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="common_banner clear">
                  <h1>Contact us</h1>
                  <p>Zonega Car</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Content Section --> */}
        <section className="contact_content_section clear">
          <div className="container">
            <div className="ccontent_upper">
              <div className="row">
                <div className="col-md-4">
                  <div className="ccont_div clear">
                    <div className="ccont_cont clear">
                      <h4>Sell your car</h4>
                      <p>Get a price for your car from an advisor</p>
                    </div>
                    <div className="ccont_cont_link clear">
                      <a href="javascript:void(0)">{this.state.data.phone}</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ccont_div clear">
                    <div className="ccont_cont clear">
                      <h4>Common questions</h4>
                      <p>Find answers in our online support</p>
                    </div>
                    <div className="ccont_cont_link clear">
                      <a href="/faq">Go to FAQs</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ccont_div clear">
                    <div className="ccont_cont clear">
                      <h4>Bid Online</h4>
                      <p>Bid Online And Buy Your Cars Easily!</p>
                    </div>
                    <div className="ccont_cont_link clear">
                      <a href="/cars">Let's Bid</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="ccontent_middle clear">
              <div className="row">
                <div className="col-md-6">
                  <div className="ccontent_middle_left clear">
                    <div className="ccont_md_left_cont clear">
                      <h3>Zonega Car</h3>
                      <p>{this.state.data.contact_page_content}</p>
                    </div>
                    <div className="ccont_md_left_cont clear">
                      <h3>Address</h3>
                      <p>{this.state.data.address}</p>
                    </div>
                    <div className="ccont_md_left_cont clear">
                      <h3>Contact us</h3>
                      <p>{this.state.data.phone}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    style={{
                      background: "#d9d9d9",
                      padding: "4% 3% 4% 3%",
                      marginBottom: "2%",
                      borderRadius: "2%",
                      marginRight: "2%",
                    }}
                  >
                    <h3>Contact Us</h3>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your First Name here"
                        onChange={(event) => {
                          this.setState({ first_name: event.target.value });
                        }}
                        value={this.state.first_name}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Last Name"
                        onChange={(event) => {
                          this.setState({ last_name: event.target.value });
                        }}
                        value={this.state.last_name}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Email"
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                        value={this.state.email}
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        rows="3"
                        placeholder="Leave a comment"
                        onChange={(event) => {
                          this.setState({ msg: event.target.value });
                        }}
                        value={this.state.msg}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-danger btn-block"
                        onClick={this.send_sms}
                      >
                        Contact
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ccontent_form clear">
            <div className="container">
              <div className="row">
                <div className="clear">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7370.96017816553!2d88.39448512447932!3d22.5236818013094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0276a96c201da9%3A0x8e1358775d132cf7!2sVIP%20Nagar%2C%20Kolkata%2C%20West%20Bengal%20700100%2C%20India!5e0!3m2!1sen!2sbd!4v1594103405048!5m2!1sen!2sbd"
                    width="1100"
                    height="400"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default withAlert()(Contact);
