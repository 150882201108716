import React, { Component } from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      password: "",
    };
    this.login = this.login.bind(this);
  }

  login() {
    const alert = this.props.alert;
    const password = this.state.password;
    const requestID = localStorage.getItem("requestID");
    const verifyType = localStorage.getItem("verifyType");
    var push = "/";
    var url = "";
    if (verifyType === "booking") {
      url = `api/Api/otp`;
    } else {
      url = `api/Api/register_otp`;
      push = "/profile";
    }
    if (password === "") {
      alert.error("Please Enter OTP!");
    } else {
      const options = {
        headers: { "X-API-KEY": "19913218251000224" },
      };
      axios
        .post(
          this.state.base_url + url,
          {
            token: this.state.password,
            bookingID: requestID,
          },
          options
        )
        .then((response) => {
          if (response.data.status === 200) {
            this.setState({
              password: "",
            });
            if (response.data.status === 200) {
              alert.success("Verified in successfully!");
              setTimeout(
                function () {
                  this.props.history.push(push);
                }.bind(this),
                2000
              );
            } else {
              alert.error("You have entered wrong OTP!");
            }
          } else {
            alert.error("Something went wrong!");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <>
        <Head />
        <section className="common_banner_section clear">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="common_banner clear">
                  <h1>Phone number verification</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Content Section --> */}
        <section className="contact_content_section clear">
          <div className="container">
            <div className="ccontent_upper"></div>
          </div>

          <div className="container">
            <div className="ccontent_middle clear">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div
                    style={{
                      background: "#d9d9d9",
                      padding: "4% 3% 4% 3%",
                      marginBottom: "2%",
                      borderRadius: "2%",
                      marginRight: "2%",
                    }}
                  >
                    <h3>Verify your phone number</h3>

                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter OTP"
                        onChange={(event) => {
                          this.setState({ password: event.target.value });
                        }}
                        value={this.state.password}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-danger btn-block"
                        onClick={this.login}
                      >
                        Verify Now
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (id) => dispatch({ type: "SIGN_IN", value: id }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAlert()(withRouter(Verify)));
