import React, { Component } from "react";
export default class Bid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return <>Hello</>;
  }
}
