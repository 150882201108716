import React from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

class Cars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      cars: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get(this.state.base_url + `api/Api/cars/900000`).then((res) => {
      this.setState({ cars: res.data });
    });
    this.myInterval = setInterval(() => {
      axios.get(this.state.base_url + `api/Api/cars/900000`).then((res) => {
        this.setState({ cars: res.data });
      });
    }, 5000);
  }

  render() {
    return (
      <>
        <Head />
        <section className="content clear">
          <div className="bid clear">
            <h3>Availabe cars for bidding</h3>
            <p>Find your car at a low price</p>
            <div className="row">
              {this.state.cars.map((c) => (
                <div className="column" key={c.id}>
                  <img
                    src={this.state.base_url + "uploads/" + c.photo}
                    width="300"
                    height="200"
                    className="img"
                    alt="cars "
                  />
                  <p style={{ fontSize: "15px" }}>
                    <span>
                      {c.make}, {c.model}
                    </span>
                    <span style={{ float: "right" }}>
                      Price: {c.auction_price}
                    </span>
                  </p>
                  <span>Year: {c.year}</span>
                  <Link
                    className="btn btn-sm btn-danger"
                    to={{
                      pathname: "/cars-details",
                      state: {
                        carID: c.id,
                      },
                    }}
                    style={{ float: "right" }}
                  >
                    View Details
                  </Link>
                  {/* <Link
                    to={{
                      pathname: "/bid",
                      state: {
                        carID: c.id,
                      },
                    }}
                    className="btn btn-sm btn-success"
                    style={{ float: "right" }}
                  >
                    Bid Now
                  </Link> */}
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch({ type: "SIGN_IN" }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cars);
