import React, { Component } from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      email: "",
      password: "",
    };
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged === "true") {
      this.props.history.push(`/`);
    }
  }
  login() {
    const alert = this.props.alert;
    const email = this.state.email;
    const password = this.state.password;
    if (email === "" && password === "") {
      alert.error("Please fill up the form!");
    } else {
      const options = {
        headers: { "X-API-KEY": "19913218251000224" },
      };
      axios
        .post(
          this.state.base_url + `api/Api/login`,
          {
            email: this.state.email,
            password: this.state.password,
          },
          options
        )
        .then((response) => {
          if (response.data.status === 200) {
            this.setState({
              email: "",
              password: "",
            });
            alert.success("Logged in successfully!");
            const { login } = this.props;
            const d = login(response.data.customerID);
            localStorage.setItem("requestID", response.data.customerID);
            localStorage.setItem("verifyType", "register");
            this.props.history.push(`/profile`);
          } else {
            alert.error("Invalid email or password");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <>
        <Head />
        <section className="common_banner_section clear">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="common_banner clear">
                  <h1>Member Login</h1>
                  <p>Become a member of CashMyCar</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Content Section --> */}
        <section className="contact_content_section clear">
          <div className="container">
            <div className="ccontent_upper"></div>
          </div>

          <div className="container">
            <div className="ccontent_middle clear">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div
                    style={{
                      background: "#d9d9d9",
                      padding: "4% 3% 4% 3%",
                      marginBottom: "2%",
                      borderRadius: "2%",
                      marginRight: "2%",
                    }}
                  >
                    <h3>Member Login</h3>

                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Email"
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                        value={this.state.email}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter your Password"
                        onChange={(event) => {
                          this.setState({ password: event.target.value });
                        }}
                        value={this.state.password}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-danger btn-block"
                        onClick={this.login}
                      >
                        Login
                      </button>
                      <Link className="nav-link" to="/be-partner">
                        <div className="hover-item">
                          Don't have account? Register Now
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (id) => dispatch({ type: "SIGN_IN", value: id }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(Login));
