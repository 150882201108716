import React from "react";
import {
  BrowserRouter as Router,
  withRouter,
  browserHistory,
} from "react-router-dom";
import Route from "react-router-dom/Route";
import Home from "./Components/Home";
import Location from "./Components/Location";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Faq from "./Components/Faq";
import Bid from "./Components/Bid";
import Cars from "./Components/Cars";
import Register from "./Components/Register";
import Login from "./Components/Login";
import Details from "./Components/Details";
import Profile from "./Components/Profile";
import Privacy from "./Components/Privacy";
import Terms from "./Components/Terms";
import Verify from "./Components/Verify";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { connect } from "react-redux";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_RIGHT,
};

class App extends React.Component {
  render() {
    return (
      <Router history="browserHistory">
        <div className="App">
          <Route path={"/"} component={Home} exact />
          <Route path={"/location"} component={Location} />
          <Route path={"/about-us"} component={About} />
          <Route path={"/faq"} component={Faq} />
          <Route path={"/cars"} component={Cars} />
          <Route path={"/bid"} component={Bid} />
          <Route path={"/profile"} component={Profile} />
          <Route path={"/privacy-policy"} component={Privacy} />
          <Route path={"/terms-condition"} component={Terms} />

          <Provider template={AlertTemplate} {...options}>
            <Route path={"/be-partner"} component={Register} />
            <Route path={"/contact"} component={Contact} />
            <Route path={"/login"} component={Login} />
            <Route path={"/cars-details"} component={Details} />
            <Route path={"/verify"} component={Verify} />
          </Provider>
          {/* <Route path={"/:id"} component={Home_new} /> */}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch({ type: "SIGN_IN" }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
