import React from "react";
import axios from "axios";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";

class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      brand: [],
      brandValue: "",
      model: [],
      modelValue: "",
      modelDisable: true,
      year: [],
      yearValue: "",
      yearDisable: true,
      varient: "",
      variantDisable: true,
      states: [],
      stateValue: 0,
      stateDisable: true,
      driven: "",
      drivenDisable: true,
      phone: "",
      sale_date: "",
      buttonText: "Book a free inspection at your home",
    };
    this.get_model = this.get_model.bind(this);
    this.get_year = this.get_year.bind(this);
    this.get_state = this.get_state.bind(this);
    this.verify = this.verify.bind(this);
  }

  componentDidMount() {
    axios.get(this.state.base_url + `api/Api/brand`).then((res) => {
      this.setState({ brand: res.data.list });
    });
  }

  get_model(event) {
    axios
      .get(this.state.base_url + `api/Api/model/` + event.target.value)
      .then((res) => {
        this.setState({ model: res.data.list });
      });
    this.setState({ brandValue: event.target.value });
    this.setState({ modelDisable: false });
  }
  get_year(event) {
    axios.get(this.state.base_url + `api/Api/year`).then((res) => {
      this.setState({ year: res.data.list });
    });
    this.setState({ modelValue: event.target.value });
    this.setState({ yearDisable: false });
  }
  get_state(event) {
    axios.get(this.state.base_url + `api/Api/state`).then((res) => {
      this.setState({ states: res.data.list });
    });
    this.setState({ varient: event.target.value });
    this.setState({ stateDisable: false });
  }
  verify() {
    const alert = this.props.alert;
    const brandID = this.state.brandValue;
    const modelID = this.state.modelValue;
    const yearID = this.state.yearValue;
    const varient = this.state.varient;
    const stateID = this.state.stateValue;
    const driven = this.state.driven;
    const phone = this.state.phone;
    const sale_date = this.state.sale_date;
    const l_id = this.props.locationID;
    let locationID = "";
    if (l_id) {
      locationID = l_id;
    }
    if (
      brandID === "" ||
      modelID === "" ||
      yearID === "" ||
      varient === "" ||
      stateID === "" ||
      driven === "" ||
      phone === "" ||
      sale_date === ""
    ) {
      alert.error("Please fill up the form!");
    } else if (phone.length < 10) {
      alert.error("Mobile number must be at least 10 characters");
    } else {
      this.setState({ buttonText: "Sending your booking request..." });
      const options = {
        headers: { "X-API-KEY": "19913218251000224" },
      };
      axios
        .post(
          this.state.base_url + `api/Api/save_booking`,
          {
            brandID: this.state.brandValue,
            modelID: this.state.modelValue,
            yearID: this.state.yearValue,
            varient: this.state.varient,
            stateID: this.state.stateValue,
            driven: this.state.driven,
            phone: this.state.phone,
            sale_date: this.state.sale_date,
            partnerID: locationID,
          },
          options
        )
        .then((response) => {
          this.setState({
            base_url: `http://zonega.in/`,
            brandValue: "",
            model: [],
            modelValue: "",
            modelDisable: true,
            year: [],
            yearValue: "",
            yearDisable: true,
            varient: "",
            variantDisable: true,
            states: [],
            stateValue: 0,
            stateDisable: true,
            driven: 0,
            drivenDisable: true,
            phone: "",
            sale_date: "",
            buttonText: "Book a free inspection at your home",
          });
          alert.success("Your request has been saved!");
          localStorage.setItem("requestID", response.data.id);
          localStorage.setItem("verifyType", "booking");
          setTimeout(
            function () {
              this.props.history.push("/verify");
            }.bind(this),
            2000
          );
        })
        .catch(function (error) {
          this.setState({ buttonText: "Book a free inspection at your home" });
          console.log(error);
        });
    }
  }
  render() {
    return (
      <>
        <form className="bform">
          <div className="form-group">
            <select
              className="form-control"
              onChange={this.get_model}
              value={this.state.brandValue}
            >
              <option value="null">Brand</option>
              {this.state.brand.map((item) => (
                <option key={item.brandID} value={item.brandID}>
                  {item.brand_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <select
              className="form-control"
              disabled={this.state.modelDisable}
              onChange={this.get_year}
            >
              <option value="">Model</option>
              {this.state.model.map((item) => (
                <option key={item.modelID} value={item.modelID}>
                  {item.model_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <select
              className="form-control"
              disabled={this.state.yearDisable}
              onChange={(event) => {
                this.setState({ variantDisable: false });
                this.setState({ yearValue: event.target.value });
              }}
            >
              <option value="">Year</option>
              {this.state.year.map((item) => (
                <option key={item.yearID} value={item.yearID}>
                  {item.years}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <select
              className="form-control"
              disabled={this.state.variantDisable}
              onChange={this.get_state}
              value={this.state.varient}
            >
              <option value="">Month</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          </div>
          <div className="form-group">
            <select
              className="form-control"
              disabled={this.state.stateDisable}
              onChange={(event) => {
                this.setState({ drivenDisable: false });
                this.setState({ stateValue: event.target.value });
              }}
            >
              <option value="">Car Registrated State</option>
              {this.state.states.map((item) => (
                <option key={item.stateID} value={item.stateID}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              disabled={this.state.drivenDisable}
              onChange={(event) => {
                this.setState({ driven: event.target.value });
              }}
              placeholder="Kilometers Driven"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Enter your phone number"
              onChange={(event) => {
                this.setState({ phone: event.target.value });
              }}
              value={this.state.phone}
            />
          </div>
          <div className="form-group">
            <select
              className="form-control"
              onChange={(event) => {
                this.setState({ sale_date: event.target.value });
              }}
              value={this.state.sale_date}
            >
              <option value="">When do you want to sell your car?</option>
              <option value="Immediately">Immediately</option>
              <option value="7-15 Days">7-15 Days</option>
              <option value="15-30 Days">15-30 Days</option>
              <option value="No Decided">No Decided</option>
            </select>
          </div>
          <p>
            We don't buy <strong>commercial cars.</strong>
          </p>
          <p>
            By clicking "Book a free inspection" you agree to our{" "}
            <strong>Terms & Policies.</strong>
          </p>
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.verify}
            style={{ fontSize: "16px" }}
          >
            {this.state.buttonText}
          </button>
        </form>
      </>
    );
  }
}

export default withAlert()(withRouter(Booking));
