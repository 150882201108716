import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {this.props.list.map((t) => (
          <div className="location_details clear" key={t.PartnerID}>
            <h6>{t.name}</h6>
            <p>{t.address}</p>
            <span className="col-md-6">
              <button
                className="btn btn-sm btn-primary "
                onClick={() => this.props.clickHandle(t)}
              >
                Location Details
              </button>
            </span>
            {console.log(t.partnerID)}
            <span className="col-md-6">
              <Link
                className="btn btn-sm btn-success"
                to={{
                  pathname: "/",
                  state: {
                    partnerID: t.partnerID,
                  },
                }}
              >
                Book Here
              </Link>
            </span>
          </div>
        ))}
      </>
    );
  }
}
