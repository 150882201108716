import React, { Component } from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      car: [],
      photo: [],
      auction: [],
      price: "",
    };
    this.bid = this.bid.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const id = this.props.history.location.state.carID;

    axios.get(this.state.base_url + `api/Api/details/${id}`).then((res) => {
      this.setState({ car: res.data });
      localStorage.setItem("auction_status", res.data.auction_status);
    });
    axios.get(this.state.base_url + `api/Api/photo/${id}`).then((res) => {
      this.setState({ photo: res.data });
    });
    const isLogged = localStorage.getItem("isLogged");
    const auction_status = localStorage.getItem("auction_status");
    // console.log(status);

    this.myInterval = setInterval(() => {
      if (this.props.isLogged) {
        if (auction_status === "true") {
          axios
            .get(this.state.base_url + `api/Api/auction_history/${id}`)
            .then((res) => {
              this.setState({ auction: res.data });
              console.log(res);
            });
        }
      }
    }, 5000);
  }
  bid() {
    const alert = this.props.alert;
    const price = this.state.price;
    const carID = this.state.car.id;
    const customerID = localStorage.getItem("token");
    if (price === "") {
      alert.error("Please fill up the form!");
    } else {
      const options = {
        headers: { "X-API-KEY": "19913218251000224" },
      };
      axios
        .post(
          this.state.base_url + `api/Api/add_bid`,
          {
            price: price,
            carID: carID,
            customerID: customerID,
          },
          options
        )
        .then((response) => {
          this.setState({
            price: "",
          });
          alert.success("Your request has been saved!");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  render() {
    const auction_status = localStorage.getItem("auction_status");

    var i = 0;
    return (
      <>
        <Head />
        <section className="contact_content_section clear">
          <div className="container">
            <Carousel>
              {this.state.photo.map((t) => (
                <div key={t.id}>
                  <img
                    src={this.state.base_url + "uploads/" + t.photo}
                    alt="cars"
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div className="container">
            <div className="col-md-12">
              <h3>Car Details</h3>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>Brand</th>
                    <th>Model</th>
                    <th>Year</th>
                  </tr>
                  <tr>
                    <td>{this.state.car.make}</td>

                    <td>{this.state.car.model}</td>
                    <td>{this.state.car.year}</td>
                  </tr>
                  <tr>
                    <th>Auction Price</th>
                    <th>Auction Start</th>

                    <th>Auction End</th>
                  </tr>
                  <tr>
                    <td>{this.state.car.auction_price}</td>
                    <td>{this.state.car.auction_start}</td>
                    <td>{this.state.car.auction_end}</td>
                  </tr>
                  <tr>
                    <td colSpan="4">{this.state.car.description}</td>
                  </tr>
                </tbody>
              </table>

              {this.props.isLogged ? (
                <div className="row">
                  <div className="col-md-8">
                    <h3>Auction History</h3>
                    <table className="table table-bordered">
                      <thead className="bg-priamry">
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.auction.map((t) => (
                          <tr key={t.id}>
                            <td>{++i}</td>
                            <td>{t.name}</td>
                            <td>{t.date}</td>
                            <td>{t.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <h3>Place your bid</h3>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(event) => {
                                this.setState({ price: event.target.value });
                              }}
                              placeholder="Enter your bid amount"
                              value={this.state.price}
                            />
                          </td>
                          <td>
                            <button
                              type="submit"
                              className="btn btn-danger btn-block"
                              onClick={this.bid}
                            >
                              Submit
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-md-12" style={{ marginBottom: "2%" }}>
                    <table className="table">
                      <tr>
                        <th>Please login for placing your bid</th>
                        <td>
                          <Link to="/login" className="btn btn-danger">
                            Login
                          </Link>
                        </td>
                      </tr>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (id) => dispatch({ type: "SIGN_IN", value: id }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(Login));
