import React, { Component } from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { connect } from "react-redux";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      data: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get(this.state.base_url + `api/Api/about`).then((res) => {
      this.setState({ data: res.data });
    });
  }
  render() {
    return (
      <>
        <Head />
        <section className="common_banner_section clear">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="common_banner clear">
                  <h1>Who we are</h1>
                  <p>{this.state.data.tagline}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutus_content_section clear">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="ab_us_upper clear">
                  <h4>{this.state.data.first_title}</h4>
                  <p>{this.state.data.first_details}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ab_us_bottom_right clear">
                  <img
                    src={
                      this.state.base_url +
                      "/uploads/" +
                      this.state.data.first_photo
                    }
                    alt="About us"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="ab_us_bottom_right clear">
                  <img
                    src={
                      this.state.base_url +
                      "/uploads/" +
                      this.state.data.second_photo
                    }
                    alt="about us"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="ab_us_bottom_left clear">
                  <h4>{this.state.data.second_title}</h4>
                  <p>{this.state.data.second_details}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch({ type: "SIGN_IN" }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(About);
