import React from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { connect } from "react-redux";

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      info: [],
    };
  }
  componentDidMount() {
    axios.get(this.state.base_url + `api/Api/general`).then((res) => {
      this.setState({ info: res.data.privacy.split("#") });
    });
  }

  render() {
    return (
      <>
        <Head />
        <section className="common_banner_section clear">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="common_banner clear">
                  <h1>Privacy Policy</h1>
                  <p>Your privacy is our responsibility</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content clear" style={{ minHeight: "50vh" }}>
          <div className="bid clear">
            <ul>
              {this.state.info.map((t) => (
                <li key={Math.random()}>{t}</li>
              ))}
            </ul>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch({ type: "SIGN_IN" }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
