import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

class Head extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      data: [],
    };
  }
  componentDidMount() {
    axios.get(this.state.base_url + `api/Api/general`).then((res) => {
      this.setState({ data: res.data });
    });
    const isLogged = localStorage.getItem("isLogged");
    const token = localStorage.getItem("token");
    if (isLogged === "true") {
      const { login } = this.props;
      const d = login(token);
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.data.title}</title>
          <link rel="canonical" href={this.state.base_url} />
          <meta name="description" content={this.state.data.meta_description} />
          <meta name="keywords" content={this.state.data.meta_keyword} />
        </Helmet>
        <header className="header clear">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/">
              <div className="logoimg">
                <img
                  className="logo"
                  src={this.state.base_url + "uploads/" + this.state.data.logo}
                  alt="zonegacar"
                />
              </div>
            </Link>
            <a
              href="whatsapp://send?phone=918907789077&amp;text=We want to sell my car. Please text us back.&amp;source=&amp;data="
              target="_blank"
              style={{
                color: "#05ba41",
                fontSize: "42px",
                float: "right",
                marginLeft: "30%",
              }}
              id="whatsapp_left"
            >
              <i className="fa fa-whatsapp" aria-hidden="true"></i>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav"></ul>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    <div className="hover-item">Home</div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/cars">
                    <div className="hover-item">Buy Cars</div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about-us">
                    <div className="hover-item">About us</div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/location">
                    <div className="hover-item">Our Showroom</div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    <div className="hover-item">Contact Us</div>
                  </Link>
                </li>

                {this.props.isLogged ? (
                  <>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/"
                        onClick={this.props.logout}
                      >
                        <div className="hover-item">Logout</div>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/profile">
                        <div className="hover-item">Profile</div>
                      </Link>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <Link className="nav-link" to="/be-partner">
                      <div className="hover-item">Become Channel partner</div>
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <div className="hover-item">
                    <a
                      href="whatsapp://send?phone=918907789077&amp;text=We want to place an order for school uniforms. Please text us back.&amp;source=&amp;data="
                      target="_blank"
                      style={{
                        color: "#05ba41",
                        fontSize: "30px",
                        float: "right",
                        marginLeft: "30%",
                      }}
                      id="whatsapp_right"
                    >
                      <i className="fa fa-whatsapp" aria-hidden="true"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (id) => dispatch({ type: "SIGN_IN", value: id }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Head);
