import React from "react";
import Head from "./Head";
import Footer from "./Footer";
import Banner from "./Banner";
import axios from "axios";
import { connect } from "react-redux";

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Link } from "react-router-dom";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_RIGHT,
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      how_one_icon: "",
      how_one_title: "",
      how_one_details: "",
      how_two_icon: "",
      how_two_title: "",
      how_two_details: "",
      how_thee_icon: "",
      how_thee_title: "",
      how_thee_details: "",
      why_choose_photo: "",
      why_choose_details: [],
      cars: [],
      testimonial: [],
    };
    this.props = {
      location: { location: { state: "" } },
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get(this.state.base_url + `api/Api/homepage`).then((res) => {
      this.setState({
        how_one_icon: this.state.base_url + "/uploads/" + res.data.how_one_icon,
      });
      this.setState({ how_one_title: res.data.how_one_title });
      this.setState({ how_one_details: res.data.how_one_details });
      this.setState({
        how_two_icon: this.state.base_url + "/uploads/" + res.data.how_two_icon,
      });
      this.setState({ how_two_title: res.data.how_two_title });
      this.setState({ how_two_details: res.data.how_two_details });
      this.setState({
        how_three_icon:
          this.state.base_url + "/uploads/" + res.data.how_three_icon,
      });
      this.setState({ how_three_title: res.data.how_three_title });
      this.setState({ how_three_details: res.data.how_three_details });
      this.setState({
        why_choose_photo:
          this.state.base_url + "/uploads/" + res.data.why_choose_photo,
      });
      this.setState({
        why_choose_details: res.data.why_choose_details.split("#"),
      });
    });
    axios.get(this.state.base_url + `api/Api/cars/9`).then((res) => {
      this.setState({ cars: res.data });
    });
    this.myInterval = setInterval(() => {
      axios.get(this.state.base_url + `api/Api/cars/9`).then((res) => {
        this.setState({ cars: res.data });
      });
    }, 10000);

    axios.get(this.state.base_url + `api/Api/testimonial`).then((res) => {
      this.setState({ testimonial: res.data });
    });
  }
  get get_locationState() {
    return this.props.location.state !== undefined
      ? this.props.location.state
      : "";
  }
  set_active(i) {
    if (i === 1) {
      return "active";
    } else {
      return "";
    }
  }
  render() {
    const {
      match: { params },
    } = this.props;
    const partnerID = this.get_locationState;
    var i = 1;
    var active = "";
    return (
      <>
        <Head />
        <Banner locationID={partnerID} />
        <section className="content clear">
          {/* <!-- Top content Section --> */}
          <div className="top_content clear">
            <div className="container">
              <h3>How it works </h3>
              <div className="row">
                <div className="col-md-4">
                  <div className="topcontents">
                    <img src={this.state.how_one_icon} alt="image" />
                    <h4>{this.state.how_one_title}</h4>
                    <p>{this.state.how_one_details}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="topcontents">
                    <img src={this.state.how_two_icon} alt="image" />
                    <h4>{this.state.how_two_title}</h4>
                    <p>{this.state.how_two_details}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="topcontents">
                    <img src={this.state.how_three_icon} alt="image" />
                    <h4>{this.state.how_two_title}</h4>
                    <p>{this.state.how_three_details}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Middle content Section --> */}
          <div className="middle_content clear">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="mcontent_left clear">
                    <h3>Why choose Zonega Car?</h3>
                    <p>How do we compare with other ways to sell?</p>
                    <img src={this.state.why_choose_photo} alt="image" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mcontent_right clear">
                    <ul>
                      {this.state.why_choose_details.map((t) => (
                        <li key={Math.random()}>{t}</li>
                      ))}
                      <Link to="/be-partner" className="btn btn-danger">
                        Become a channel partner
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Slider Section --> */}
          <div className="slider_part ">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3>See what our customers are saying about us</h3>

                  <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {this.state.testimonial.map((t) => (
                        <div
                          className={"item carousel-item " + this.set_active(i)}
                          key={i++}
                        >
                          <div className="img-box">
                            <img
                              src={this.state.base_url + "uploads/" + t.photo}
                              alt=""
                            />
                          </div>
                          <p className="testimonial">{t.msg}</p>
                          <p className="overview">
                            <b>{t.name}</b> <a href="#">{t.designation}</a>
                          </p>
                        </div>
                      ))}
                    </div>
                    {/* <!-- Carousel controls --> */}
                    <a
                      className="carousel-control left carousel-control-prev"
                      href="#myCarousel"
                      data-slide="prev"
                    >
                      <i className="fa fa-angle-left"></i>
                    </a>
                    <a
                      className="carousel-control right carousel-control-next"
                      href="#myCarousel"
                      data-slide="next"
                    >
                      <i className="fa fa-angle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bid clear">
            <h3>Availabe cars for bidding</h3>
            <p>Find your car at a low price</p>
            <div className="row">
              {this.state.cars.map((c) => (
                <div className="column" key={c.id}>
                  <img
                    src={this.state.base_url + "uploads/" + c.photo}
                    width="300"
                    height="200"
                    className="img"
                  />
                  <p style={{ fontSize: "15px" }}>
                    <span>
                      {c.make}, {c.model}
                    </span>
                    <span style={{ float: "right" }}>
                      Price: {c.auction_price}
                    </span>
                  </p>
                  <span>Year: {c.year}</span>
                  <Link
                    className="btn btn-sm btn-danger"
                    to={{
                      pathname: "/cars-details",
                      state: {
                        carID: c.id,
                      },
                    }}
                    style={{ float: "right" }}
                  >
                    View Details
                  </Link>
                  {/* <Link
                    to={{
                      pathname: "/bid",
                      state: {
                        carID: c.id,
                      },
                    }}
                    className="btn btn-sm btn-success"
                    style={{ float: "right" }}
                  >
                    Bid Now
                  </Link> */}
                </div>
              ))}
            </div>
            <div className="row centers">
              <Link
                to="/cars"
                className="btn btn-danger"
                style={{ border: "1px solid gray" }}
              >
                View all Cars
              </Link>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch({ type: "SIGN_IN" }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
