const isLogged = (state = false, action) => {
  switch (action.type) {
    case "SIGN_IN":
      localStorage.setItem("isLogged", true);
      localStorage.setItem("token", action.value);
      return {
        status: true,
        token: action.value,
      };
      break;
    case "SIGN_OUT":
      localStorage.setItem("isLogged", false);
      localStorage.setItem("token", null);
      return false;
      break;
    default:
      return state;
      break;
  }
};

export default isLogged;
