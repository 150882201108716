import React from "react";
import axios from "axios";

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Booking from "./Booking";
const options = {
  timeout: 5000,
  position: positions.BOTTOM_RIGHT,
};

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      background: "",
      banner_heading: "",
      banner_sub_heading: [],
    };
  }
  componentDidMount() {
    axios.get(this.state.base_url + `api/Api/homepage`).then((res) => {
      this.setState({
        background: this.state.base_url + "/uploads/" + res.data.banner,
      });
      this.setState({ banner_heading: res.data.banner_heading });
      this.setState({
        banner_sub_heading: res.data.banner_sub_heading.split("#"),
      });
    });
  }
  render() {
    return (
      <>
        <section
          className="banner clear"
          style={{
            backgroundImage: `url(${this.state.background})`,
            position: "relative",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
          }}
        >
          <div className="layer">
            {/* <!-- Left side of Banner Section --> */}
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="banner_left clear">
                    <Provider template={AlertTemplate} {...options}>
                      <Booking locationID={this.props.locationID} />
                    </Provider>
                  </div>
                </div>
                {/* <!-- Right side of Banner Section --> */}
                <div className="col-md-6">
                  <div className="banner_right clear" style={{ color: "#fff" }}>
                    <h1>{this.state.banner_heading}</h1>
                    <ul>
                      {this.state.banner_sub_heading.map((t) => (
                        <li key={Math.random()}>{t}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Banner;
