import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      data: [],
    };
  }
  componentDidMount() {
    axios.get(this.state.base_url + `api/Api/general`).then((res) => {
      this.setState({ data: res.data });
    });
  }
  render() {
    return (
      <>
        <section className="footer_section clear">
          <div className="container">
            <div className="ufooter_middle">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/terms-condition">Terms and conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Terms & Policies</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQs</Link>
                    </li>
                  </ul>
                </div>

                <div className="col-md-4 col-sm-12">
                  <h3>Do you want to sell your car?</h3>
                  <p>
                    Our online evaluation takes less than a minute to complete.
                  </p>
                  <Link className="btn btn-danger" to="/">
                    Book a free inspection at your home
                  </Link>
                </div>
                <div className="col-md-4 col-sm-12">
                  <h3>Contact Info</h3>
                  <p>
                    {this.state.data.address}
                    <br />
                    Email: {this.state.data.email} <br />
                    Phone: {this.state.data.phone}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Footer Bottom section --> */}
          <div className="bottom_footer clear">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <span>
                    <a href="https://prantiksoft.com">PRANTIK-SOFT</a> &copy;
                    All rights reserved
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Footer;
