import React, { Component } from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      name: "",
      email: "",
      phone: "",
      address: "",
      password: "",
      buttonText: "Register",
    };
    this.register = this.register.bind(this);
  }

  componentDidMount() {
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged === "true") {
      this.props.history.push(`/`);
    }
  }
  register() {
    const alert = this.props.alert;
    const name = this.state.name;
    const email = this.state.email;
    const phone = this.state.phone;
    const address = this.state.address;
    const password = this.state.password;
    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      address === "" ||
      password === ""
    ) {
      alert.error("Please fill up the form!");
    } else {
      this.setState({ buttonText: "Sending request..." });
      const options = {
        headers: { "X-API-KEY": "19913218251000224" },
      };
      axios
        .post(
          this.state.base_url + `api/Api/add_customer`,
          {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address,
            password: this.state.password,
          },
          options
        )
        .then((response) => {
          this.setState({
            name: "",
            email: "",
            phone: "",
            address: "",
            password: "",
            buttonText: "Register",
          });
          const { login } = this.props;
          const d = login(response.data.customerID);
          alert.success("Your request has been saved!");
          localStorage.setItem("requestID", response.data.customerID);
          localStorage.setItem("verifyType", "register");
          console.log(response);
          setTimeout(
            function () {
              this.props.history.push("/verify");
            }.bind(this),
            2000
          );
        })
        .catch(function (error) {
          this.setState({ buttonText: "Register" });
          console.log(error);
        });
    }
  }

  render() {
    return (
      <>
        <Head />
        <section className="common_banner_section clear">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="common_banner clear">
                  <h1>Channel Partner Registration</h1>
                  <p>Become a channel partner of Zonega Car</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Content Section --> */}
        <section className="contact_content_section clear">
          <div className="container">
            <div className="ccontent_upper"></div>
          </div>

          <div className="container">
            <div className="ccontent_middle clear">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div
                    style={{
                      background: "#d9d9d9",
                      padding: "4% 3% 4% 3%",
                      marginBottom: "2%",
                      borderRadius: "2%",
                      marginRight: "2%",
                    }}
                  >
                    <h3>Channel Partner Registration</h3>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Name here"
                        onChange={(event) => {
                          this.setState({ name: event.target.value });
                        }}
                        value={this.state.name}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Email"
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                        value={this.state.email}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter your Password"
                        onChange={(event) => {
                          this.setState({ password: event.target.value });
                        }}
                        value={this.state.password}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Phone"
                        onChange={(event) => {
                          this.setState({ phone: event.target.value });
                        }}
                        value={this.state.phone}
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        rows="3"
                        placeholder="Enter your address"
                        onChange={(event) => {
                          this.setState({ address: event.target.value });
                        }}
                        value={this.state.address}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-danger btn-block"
                        onClick={this.register}
                      >
                        Register
                      </button>

                      <Link className="nav-link" to="/login">
                        <div className="hover-item">
                          Already have account? Sign in Now
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (id) => dispatch({ type: "SIGN_IN", value: id }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAlert()(Register));
