import React, { Component } from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { connect } from "react-redux";
class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      how: [],
      bid: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get(this.state.base_url + `api/Api/faq/how`).then((res) => {
      this.setState({ how: res.data });
    });
    axios.get(this.state.base_url + `api/Api/faq/bid`).then((res) => {
      this.setState({ bid: res.data });
    });
  }
  render() {
    return (
      <>
        <Head />
        <section className="common_banner_section clear">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="common_banner clear">
                  <h1>FAQs</h1>
                  <p>How to get in touch with CashMyCar</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq-qus clear">
          <div className="faq_content clear">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h3 id="faqh3">How does Cash My Car work?</h3>
                  <div className="location_sidebars clear">
                    <div className="accordion" id="accordionExample">
                      {/* Start of Card  */}
                      {this.state.how.map((t) => (
                        <div className="card" key={t.id}>
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <span
                                className="btn btn-link btn-block text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target={"#collapseOne" + t.id}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                style={{
                                  color: "#003035",
                                  fontWeight: "bold",
                                  textDecoration: "none",
                                }}
                                value={t.id}
                              >
                                {t.title}
                                <i
                                  className="fa fa-arrow-right"
                                  style={{ float: "right", color: "#003035" }}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </h2>
                          </div>
                          <div
                            id={"collapseOne" + t.id}
                            className="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body">{t.details}</div>
                          </div>
                        </div>
                      ))}
                      {/* End of card  */}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <h3 id="faqh3">How does Bidding work?</h3>
                  <div className="location_sidebars clear">
                    <div className="accordion" id="accordionExample">
                      {/* Start of Card  */}
                      {this.state.bid.map((t) => (
                        <div className="card" key={t.id}>
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <span
                                className="btn btn-link btn-block text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target={"#collapseOne" + t.id}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                style={{
                                  color: "#003035",
                                  fontWeight: "bold",
                                  textDecoration: "none",
                                }}
                                value={t.id}
                              >
                                {t.title}
                                <i
                                  className="fa fa-arrow-right"
                                  style={{ float: "right", color: "#003035" }}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </h2>
                          </div>
                          <div
                            id={"collapseOne" + t.id}
                            className="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body">{t.details}</div>
                          </div>
                        </div>
                      ))}
                      {/* End of card  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch({ type: "SIGN_IN" }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Faq);
