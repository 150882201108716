import React, { Component } from "react";
import { compose } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import Head from "./Head";
import Footer from "./Footer";
import Partners from "./Partners";
import axios from "axios";
import { connect } from "react-redux";

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      defaultZoom={5}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      {props.markers.map((marker) => {
        const onClick = props.onClick.bind(this, marker);
        return (
          <Marker
            key={Math.random()}
            onClick={onClick}
            position={{ lat: +marker.latitude, lng: +marker.longitude }}
          >
            {props.selectedMarker === marker && (
              <InfoWindow>
                <div>
                  <div>
                    <h6>{marker.name}</h6>
                  </div>
                  <div>
                    <strong>Phone:</strong> {marker.phone}
                  </div>
                  <div>
                    <strong>Email:</strong> {marker.email}
                  </div>
                  <div>
                    <strong>Address:</strong> {marker.address}
                  </div>
                </div>
              </InfoWindow>
            )}
            }
          </Marker>
        );
      })}
    </GoogleMap>
  );
});

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      shelters: [],
      selectedMarker: false,
      partners: [],
      current_state: "",
      lat: 24.046464,
      lng: 78.919365,
    };
    this.get_partners = this.get_partners.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get(this.state.base_url + `api/Api/locations`).then((res) => {
      this.setState({ shelters: res.data });
    });
    axios.get(this.state.base_url + `api/Api/partners`).then((res) => {
      this.setState({ partners: res.data });
    });
    // console.log(this.state.partners);
  }
  handleClick = (marker, event) => {
    // console.log({ marker });
    this.setState({ selectedMarker: marker });
    this.setState({ lat: marker.latitude });
    this.setState({ lng: marker.longitude });
  };
  get_partners(id) {
    axios.get(this.state.base_url + `api/Api/partners/` + id).then((res) => {
      this.setState({ partners: res.data });
      this.setState({ current_state: id });

      // console.log(this.state.partners);
    });
  }
  render() {
    return (
      <>
        <Head />
        <section className="location_section clear">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                {/* <!-- Locations Sidebar --> */}
                <div className="location_sidebars clear">
                  <div className="accordion" id="accordionExample">
                    <div className="search_location_form clear">
                      <h3>Choose a City</h3>
                    </div>
                    {/* Start of Card  */}
                    {this.state.shelters.map((t) => (
                      <div className="card" key={t.locationID}>
                        <div className="card-header" id="headingOne">
                          <h2 className="mb-0">
                            <span
                              className="btn btn-link btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              data-target={"#collapseOne" + t.locationID}
                              aria-expanded="true"
                              aria-controls="collapseOne"
                              style={{
                                color: "#003035",
                                fontWeight: "bold",
                                textDecoration: "none",
                              }}
                              onClick={(e) => this.get_partners(t.locationID)}
                              value={t.locationID}
                            >
                              {t.name}
                              <i
                                className="fa fa-arrow-right"
                                style={{ float: "right", color: "#003035" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h2>
                        </div>
                        <div
                          id={"collapseOne" + t.locationID}
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            {this.state.current_state === t.locationID ? (
                              <Partners
                                list={this.state.partners}
                                clickHandle={this.handleClick}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* End of card  */}
                  </div>
                </div>
              </div>
              {/* <!-- Map section --> */}
              <div className="col-md-8">
                <div className="map_div clear">
                  <div id="location_map" className="clear">
                    <MapWithAMarker
                      selectedMarker={this.state.selectedMarker}
                      markers={this.state.partners}
                      onClick={this.handleClick}
                      lat={this.state.lat}
                      lng={this.state.lng}
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `93vh` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch({ type: "SIGN_IN" }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Location);
