import React from "react";
import Head from "./Head";
import Footer from "./Footer";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: `http://zonega.in/`,
      info: "",
      list: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const isLogged = localStorage.getItem("isLogged");
    const id = localStorage.getItem("token");
    localStorage.setItem("requestID", id);
    localStorage.setItem("verifyType", "register");
    if (isLogged === "false") {
      this.props.history.push(`/`);
    }
    axios.get(this.state.base_url + `api/Api/profile/${id}`).then((res) => {
      this.setState({ info: res.data });
    });
    axios
      .get(this.state.base_url + `api/Api/auction_data/${id}`)
      .then((res) => {
        this.setState({ list: res.data });
      });
  }

  render() {
    var i = 0;
    return (
      <>
        <Head />
        <section className="content clear" style={{ minHeight: "50vh" }}>
          <div className="bid clear">
            <h3>Profile</h3>
            <div className="col-md-12 table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>Name: </th>
                    <td>{this.state.info.name}</td>
                    <th>Email: </th>
                    <td>{this.state.info.email}</td>
                    <th>Phone: </th>
                    <td>
                      {this.state.info.phone}
                      {this.state.info.otp_verify === "false" ? (
                        <Link className="nav-link" to="/verify">
                          Verify
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Address: </th>
                    <td colSpan="3">{this.state.info.address}</td>
                    <th>Verification Status: </th>
                    <td>
                      {this.state.info.verified === "true"
                        ? "Verified"
                        : "Not Verified"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3>Auction History</h3>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Brand</th>
                    <th>Model</th>
                    <th>Year</th>
                    <th>Bid Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.list.map((c) => (
                    <tr key={++i}>
                      <td>{i}</td>
                      <td>{c.make}</td>
                      <td>{c.model}</td>
                      <td>{c.year}</td>
                      <td>{c.price}</td>
                      <td>{c.approval}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch({ type: "SIGN_IN" }),
    logout: () => dispatch({ type: "SIGN_OUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
